<template>
  <div class="order" @click="data.item.isDelete = false">
    <div class="order-header">
      <span
        style="position: absolute; left: 10px; top: 10px"
        @click="skip('/orderList')"
        ><el-icon><ArrowLeftBold /></el-icon
      ></span>
      <div class="order-title">配货</div>
    </div>
    <div class="order-information">
      <div class="order-information-item">
        <span class="order-information-title">订单号：</span
        >{{ data.param.order_num }}
      </div>
      <div class="order-information-item">
        <span class="order-information-title">客号：</span
        >{{ data.param.cus_name }}
      </div>
      <div style="display: flex">
        <div
          class="order-information-item"
          style="width: 43vw; margin-right: 10px"
        >
          <span class="order-information-title">已配数量：</span
          >{{ data.param.distribution_quantity }}/{{ data.param.quantity }}
        </div>
        <div class="order-information-item">
          <span class="order-information-title">已配克重：</span
          >{{ data.param.distribution_weight }}/{{ data.param.weight }}g
        </div>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">状态：</span>
        <span v-if="data.param.status_str == '待审核'" class="yellow">{{
          data.param.distributed_str
        }}</span>
        <span v-else-if="data.param.status_str == '未配齐'" class="red">{{
          data.param.distributed_str
        }}</span>
        <span v-else-if="data.param.status_str == '已配齐'" class="blue">{{
          data.param.status_str
        }}</span>
        <span v-else-if="data.param.status_str == '已出库'" class="green">{{
          data.param.status_str
        }}</span>
        <span v-else-if="data.param.status_str == '已取消'" class="info">{{
          data.param.status_str
        }}</span>
        <span v-else>{{ data.param.status_str }}</span>
      </div>
      <div class="order-information-item">
        <span>业务员备注：</span
        >{{ data.param.wor_memo }}
      </div>
    </div>
    <div class="order-body">
      <div
        class="order-item"
        v-for="(item, index) in data.param.item"
        :key="index"
        @click="item.show = !item.show"
        @touchstart="touchStartEvent"
        @touchend="touchEndEvent($event, item)"
      >
        <div class="order-item-title">
          <div style="display: flex;">
            <div style="width: 44vw; margin-right: 10px">
              配货单号：{{ item.doc_num }}
            </div>

            <div>
              配货状态：<span v-if="item.submit_status == 1" class="green">已提交</span><span v-else class="red">未提交</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 10px">
            <div style="width: 44vw; margin-right: 10px">
              已配数量：{{ item.quantity }}
            </div>
            <div>已配克重：{{ item.weight }}g</div>
          </div>
          <div style="display: flex; margin-top: 10px">
            <div style="width: 44vw; margin-right: 10px">
              <el-button
                style="width: 100px"
                type="warning"
                @click="getPrintPda(item.distribution_id)"
                plain
                >打印标签</el-button
              >
            </div>
            <el-button
              style="width: 100px"
              type="primary"
              @click="skip('/distributionList/' + item.distribution_id)"
              plain
              >配货</el-button
            >
          </div>
        </div>
        <el-button
          type="danger"
          style="height: 80px;width: 40px;"
          v-show="item.isDelete"
          @click.stop="deleteDistribution(item.distribution_id)"
          >删除</el-button
        >
      </div>
      <div style="width: 100%;height: 50px;"></div>
    </div>
    <el-button style="width: calc(100% - 20px);position: absolute;bottom: 5px;left: 10px;" type="primary" @click="addDistribution"
      >新增配货单</el-button
    >
  </div>
</template>
<script setup>
import {
  distribution_list,
  oneclick_cancel_distribution,
  add_distribution,
} from "@/api/ph";
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { print_pda } from "@/api/print";
import { ElMessage } from "element-plus";
const route = useRoute();
const router = useRouter();
const data = reactive({
  order_id: "",
  param: {},
  param2: {
    doc_num: "",
    distribution_num: "",
    cus_name: "",
    quantity: 0,
    weight: 0,
    create_time: "",
  },
  startX: 0, // 触摸开始的X坐标
  endX: 0, // 触摸结束的X坐标
  threshold: 150, // 滑动的阈值
  value3: true,
  value: "",
  item: {
    isDelete: false,
  },
  timer: null,
});
const getDistributionList = async () => {
  const res = await distribution_list({ order_id: data.order_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.param = res.data.data;
  }
};
const getPrintPda = async (distribution_id) => {
  const res = await print_pda({ distribution_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.param2 = res.data.data;
    onPrint();
  }
};
const onPrint = async () => {
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  const param = {
    text: `订单单据号:${data.param2.order_num}\r\n配货单据号:${
      data.param2.distribution_num
    }\r\n客号:${data.param2.cus_name || ""}\r\n店铺:${
      data.param2.cus_child_name || ""
    }\r\n配货件数:${data.param2.quantity}   配货克重:${
      data.param2.weight
    }\r\n配货时间:${data.param2.create_time}\r\n业务员名称:${
      data.param2.wor_name || ""
    }\r\n客户备注:${data.param2.cus_memo}\r\n业务员备注:${data.param2.wor_memo}`,
  };
  const strParam = JSON.stringify(param);
  Android_LcPrinter.js_printText(strParam);
  // 多行换行
  const hh = {
    LINE_SPACING: "1-n",
  };
  const strHh = JSON.stringify(hh);
  Android_LcPrinter.js_setLineSpacing(strHh);
  Android_LcPrinter.js_start();
};
const skip = (path) => {
  router.push({ path });
};

const touchStartEvent = (event) => {
  data.startX = event.touches[0].clientX;
};
const touchEndEvent = (event, item) => {
  if (data.item.item_id != item.item_id) data.item.isDelete = false;
  data.item = item;
  data.endX = event.changedTouches[0].clientX;
  if (data.startX - data.endX > 10) item.isDelete = true;
  if (data.endX - data.startX > 10) item.isDelete = false;
};
const deleteDistribution = async (distribution_id) => {
  const res = await oneclick_cancel_distribution({ distribution_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    getDistributionList();
  }
};
const addDistribution = () => {
  if (data.timer != null) {
    clearTimeout(data.timer);
  }
  data.timer = setTimeout(async () => {
    const res = await add_distribution({ order_id: data.order_id, from: 2 });
    if (res && res.status == 200 && res.data.code >= 1) {
      const distribution_id = res.data.data.distribution_id;
      data.timer = null;
      router.push({ path: `/distributionList/${distribution_id}` });
    }
  }, 1000);
};
onMounted(() => {
  data.order_id = route.params.order_id;
  getDistributionList();
});
</script>
<style scoped>
.order {
  width: 100vw;
  height: 100vh;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
}
.order-title {
  margin: 0 auto;
  font-weight: 600;
  /* position: absolute;
  left: calc(50% - 32px); */
  font-size: 16px;
}
.order-information {
  padding: 10px;
  color: #333;
}
.order-information .order-information-item {
  /* line-height: 25px; */
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
  /* justify-content: space-between; */
}
.order-information-title {
  width: 70px;
  text-align: justify;
  display: block;
  text-align: justify;
  text-align-last: justify;
}
/* .order-footer {
  position: fixed;
  bottom: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
} */
.order-body {
  padding: 5px 10px;
  height: calc(100vh - 162px);
  overflow-y: auto;
}
.order-item {
  background-color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 2px 10px 2px #ebe9e9;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
}
.order-item-title {
  line-height: 20px;
  /* font-weight: 600; */
  position: relative;
}
.order-item-body {
  display: flex;
}
.order-item-body img {
  margin-right: 25px;
}
.red {
  color: #f56c6c;
}
.green {
  color: #13ce66;
}
.blue {
  color: #409eff;
}
.yellow {
  color: #e6a23c;
}
.dark {
  color: #909399;
}
</style>
